import { LangService } from 'src/app/core/services/lang.service'

import { environment } from 'src/environments/environment'

export interface ITranslation<D> {
  locale: string
  data: D
  meta: never[]
  title?: string
  placeholder?: string
  searchPlaceholder?: string
}

export class TranslatableModel<T = any> {
  translations: ITranslation<T>[] = []
  translation!: ITranslation<T>
  defaultLang = environment.langList[0]
  locale: string = this.defaultLang

  constructor(item: object, langService: LangService) {
    if (item) {
      Object.assign(this, item)
    }

    if (langService) {
      this.setLang(langService.lang())
      langService.langChanged.subscribe((lang) => {
        this.setLang(lang)
      })
    }
  }

  static fromJson<T = object>(item: object, langService: LangService): T {
    const model: object = new this(item, langService)

    return <T>model
  }

  static fromJsonArray(data: object[], langService: LangService): object[] {
    const models: object[] = []

    if (data.length) {
      data.forEach((item) => {
        models.push(this.fromJson(item, langService))
      })
    }

    return models
  }

  setLang(lang: string): void {
    if (this.locale === lang && this.translation) {
      return
    }

    if (this.translations) {
      let translation = this.translations.find((itm) => itm.locale === lang)

      if (!translation) {
        translation = this.translations.find((itm) => itm.locale === this.defaultLang)
      }

      this.translation = <ITranslation<T>>translation
      if (this.translation?.data) {
        this.translation.data = this.translation.data
          ? typeof this.translation.data === 'object'
            ? this.translation.data
            : JSON.parse(<string>this.translation.data)
          : {}
      }

      this.locale = lang
    }
  }
}
