import { ApiService } from '@app/core/abstracts/api.service'
import { LanguageModel } from '@app/core/models/language.model'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { LocalStorageService } from './local-storage.service'

import { EventEmitter, Injectable, inject, signal } from '@angular/core'

import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root',
})
export class LangService extends ApiService {
  langChanged = new EventEmitter()
  langDefault: LanguageModel = <LanguageModel>{
    id: 0,
    code: environment?.langList[0] && 'ua',
    title: 'Укр',
  }

  private localStorageService = inject(LocalStorageService)
  lang = signal<string>(this.localStorageService.getItem('lang'))

  constructor() {
    super()
    if (!this.lang()) {
      const lang = environment.langList[0]

      this.localStorageService.setItem('lang', lang)
      this.lang.set(lang)
    }
  }

  list(): Observable<LanguageModel[]> {
    return this.http.get<{ languages: LanguageModel[] }>(`${this.apiFrontUrl}/language?date=${this.cacheUid()}`).pipe(
      map((data: { languages: LanguageModel[] }) => {
        return LanguageModel.fromJsonArray(<LanguageModel[]>data.languages)
      }),
    )
  }

  setLang(lang: string): void {
    if (!lang) {
      return
    }

    this.lang.set(lang)
    this.langChanged.emit(this.lang())
    this.localStorageService.setItem('lang', this.lang())
  }
}
